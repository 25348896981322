import { Component, Vue, Prop } from "vue-property-decorator";
import { TeamMember } from "@/types/team";

@Component({
  inheritAttrs: false,
  components: {
    TeamMemberActions: () =>
      import(
        "../../../../common/components/TeamMemberActions/TeamMemberActions.vue"
      ),
  },
})
export default class TeamMemberActionsMenu extends Vue {
  @Prop({ type: Object, required: true })
  private readonly teamMember!: TeamMember;
}
